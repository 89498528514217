<template>
  <div class="login-container">
    <div class="logo">
      <img src="../../assets/logo.png" alt="">
    </div>
    <div class="input_container">
      <ul class="input">
        <li>
          <img src="../../assets/images/public/phone.png" alt="">
          <div class="right">
            <input
              :class="{'has-content': phone}"
              type="tel"
              placeholder="手机号码"
              maxlength="11"
              v-model="phone"
              @input="inputPhone"
              @focus="focusInput"
              @blur="blurInput" />
            <!--<span class="focus-border"></span>-->
            <i v-show="phone.length > 0" class="iconfont icon-shanchu" @click="del"></i>
          </div>
        </li>
        <li>
          <img src="../../assets/images/public/vert.png" alt="">
          <div class="right">
            <input :class="{'has-content': code}" type="tel" placeholder="短信验证码" maxlength="6" v-model="code" @input="inputCode" @focus="focusInput" @blur="blurInput" />
            <!--<span class="focus-border"></span>-->
            <button class="sendCode" v-if="!isSendCode" @click="sendCode" :disabled="isDisable">获取验证码</button>
            <span class="sendCode" v-else @click="endTimer">{{ countTimer }}s 后获取验证码 </span>
          </div>
          <!--<i class="iconfont icon-shanchu"></i>-->
        </li>
        <li v-if="!txtTitle[titleStatus].b">
          <i class="iconfont common icon-yanzhengma"></i>
          <div class="right">
            <input :class="{'has-content': recommendCode}" type="tel" placeholder="邀请码（选填）" maxlength="11" v-model="recommendCode" @input="inputInvite" @focus="focusInput" @blur="blurInput" />
            <!--<span class="focus-border"></span>-->
          </div>
          <!--<i class="iconfont icon-shanchu"></i>-->
        </li>
      </ul>
      <div class="loginBtn">
        <button-public @transParent="loginOrRegister" :params="titleStatus" :title="`立即${txtTitle[titleStatus].a}`" />
        <!--<div v-if="txtTitle[titleStatus].b" class="register">还没有账号？<i @click="goRegister">立即注册</i></div>
        <div v-else class="register"><i @click="goLogin">去登录</i></div>-->
      </div>
    </div>

    <!--<div class="loginBottom" v-show="showHideText">
      <van-checkbox v-model="checked" @change="handleChecked" icon-size="18px" checked-color="#1487cd"></van-checkbox>
      <div class="agree">登录即代表您已阅读并同意，<router-link :to="{ path: '/register' }">《用户服务协议》</router-link> </div>
    </div>-->
  </div>
</template>

<script>
import {
  debounce,
  deviceType,
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
  validMobile
} from '../../utils'
import ButtonPublic from '../../components/module/ButtonPublic.vue'

export default {
  name: 'Login',

  components: {
    ButtonPublic
  },

  data () {
    return {
      isWeiXin: false,
      wxCode: '',
      showHideText: true,
      showBorderColor: false,
      phone: '',
      code: '',
      recommendCode: '',
      isSendCode: false, // 是否发送验证码
      countTimer: '', // 倒计时
      isDisable: false,
      btnTitle: '立即登录',
      businessStatus: false,
      checked: false,

      titleStatus: 'login',

      txtTitle: {
        login: {
          a: '登录',
          b: true
        },
        register: {
          a: '注册',
          b: false
        }
      },
      registerTxt: {

      },

      domHeight: document.documentElement.clientHeight || document.body.clientHeight, // 默认屏幕高度
      resizeHeight: document.documentElement.clientHeight || document.body.clientHeight // 实时屏幕高度
    }
  },

  mounted () {
    const that = this

    if (getLocalStorage('SET_AGREE_STATUS')) {
      this.checked = true
    }

    if (deviceType() === 'deviceIsAndroid') {
      window.onresize = () => {
        that.pageResize()
      }
    }
  },

  methods: {
    pageResize () {
      this.$nextTick(() => {
        // 键盘弹起与隐藏都会引起窗口的高度发生变化
        const resizeHeight = document.documentElement.clientHeight || document.body.clientHeight
        if (resizeHeight < this.domHeight - 0) {
          // 弹起
          this.showHideText = false
        } else {
          // 收起
          this.showHideText = true
        }
      })
    },

    /** 节流 */
    checkKeyUp: debounce(function () {

    }, 1000),

    inputPhone (e) {
      const filtered = e.target.value.replace(/^0|[^\d]/g, '')
      if (this.phone !== filtered) {
        this.phone = filtered
      }
    },

    inputCode (e) {
      const filtered = e.target.value.replace(/^0|[^\d]/g, '')
      if (this.code !== filtered) {
        this.code = filtered
      }
    },

    inputInvite (e) {
      const filtered = e.target.value.replace(/^0|[^\d]/g, '')
      if (this.recommendCode !== filtered) {
        this.recommendCode = filtered
      }
    },

    focusInput () {
      this.showBorderColor = true
      // this.showHideText = !this.isWeiXin
    },

    blurInput () {
      this.showBorderColor = false
      if (this.isWeiXin) {
        // this.showHideText = true
      }
    },

    del () {
      this.phone = ''
    },

    handleChecked (e) {
      if (e) {
        setLocalStorage('SET_AGREE_STATUS', 1)
      } else {
        removeLocalStorage('SET_AGREE_STATUS')
      }
      this.checked = e
    },

    /** 发送验证码 */
    sendCode () {
      const reg = /^((1[3,4,5,6,7,8,9]))\d{9}$/
      if (this.phone === '' || !this.phone) {
        this.$toast({
          message: '请输入手机号',
          position: 'bottom'
        })
      } else if (!reg.test(this.phone)) {
        this.$toast({
          message: '请输入正确的手机号',
          position: 'bottom'
        })
      } else {
        this.$toast.loading('玩命加载中...')
        this.$http.login.code({ phone: this.phone }).then(res => {
          if (res) {
            if (res.data.msg && !res.data.data) {
              this.$toast({
                message: res.data.msg || '获取验证码失败',
                position: 'bottom'
              })
            }
          } else {
            this.isSendCode = true
            this.countTime(60)
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },

    /** 倒计时 */
    countTime (num) {
      if (!this.wait) {
        this.countTimer = num
        this.isSendCode = true
        this.wait = setInterval(() => {
          if (this.countTimer > 0 && this.countTimer <= num) {
            this.countTimer--
          } else {
            this.isSendCode = false
            clearInterval(this.wait)
            this.wait = null
          }
        }, 1000)
      }
    },

    endTimer () {
      this.$toast({
        message: '请于倒计时结束后再获取验证码',
        position: 'bottom'
      })
    },

    /** 立即登录/注册 */
    loginOrRegister (e) {
      /* this.$router.replace({
        path: '/home',
        query: {}
      })
      return */
      if (!validMobile(this.phone)) {
        return false
      } else if (this.code === '' || !this.code) {
        this.$toast({
          message: '请您输入验证码',
          position: 'bottom'
        })
      } else if (this.code.length < 6) {
        this.$toast({
          message: '请您输入正确的验证码',
          position: 'bottom'
        })
      } else {
        this.login()
        return
        // eslint-disable-next-line no-unreachable
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          message: '玩命加载中...'
        })

        const data = {
          user_name: this.phone,
          verification_code: e === 'login' ? this.code : undefined,
          validate_status: e === 'login' ? undefined : 'noValidate'
        }

        if (e === 'login') {
          this.login(data)
        } else {
          this.$http.login.register({
            recommendCode: this.recommendCode,
            regist_type: 'CommStore',
            user_name: this.phone,
            code: this.code
          }).then(res => {
            if (res.data.code === '0000') {
              this.login(data)
            } else {
              this.$toast({
                position: 'bottom',
                message: res.data.message
              })
            }
          }).catch(err => {
            this.$toast.clear()
            this.$toast(err.data.message)
          })
        }
      }
    },

    login () {
      const newData = {
        params: {
          phone: this.phone,
          validate_code: this.code
        },
        method: 'h5-phone-register'
      }

      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: '玩命加载中...'
      })

      this.$store.dispatch('LoginByUserName2', newData).then(res => {
        this.$toast.clear()
        if (res) {
          this.$router.replace({
            path: '/home',
            query: {}
          })
        } else {
          this.$toast({
            message: res.msg,
            position: 'bottom'
          })
        }
      }, err => {
        this.$toast.clear()
        this.$toast(err.message)
      }).catch(err => {
        this.$toast.clear()
        this.$toast(err.msg || '登录失败')
      })
    },

    tel () {
      window.location.href = 'tel://1300000000'
    },

    goRegister () {
      this.titleStatus = 'register'
    },

    goLogin () {
      this.titleStatus = 'login'
    }
  },

  unmounted () {
    window.onresize = null
  }
}
</script>

<style lang="scss" scoped>
  @import "../../styles/mixin";
  .login-container {
    height: 100vh;
    padding: 40px;
    background: #fff;
    .logo {
      @include CCC;
      padding-top: 10%;
      img {
        @include wh(300px, 408px);
      }
    }

    p {
      display: block;
      padding-top: 40px;
      padding-bottom: 76px;
      @include sc(42px, $mainFontColor);
      font-weight: bold;
      text-align: left;
    }

    .input_container {
      padding-top: 30%;
    }

    .input {
      .iconfont.common {
        margin-right: 36px;
        @include sc(42px, $themeColor);
      }
      .iconfont.icon-shanchu {
        position: absolute;
        @include sc(38px, $themeColor);
        right: 0;
        top: 28px;
      }
      li {
        margin-top: 40px;
        height: 112px;
        border: solid 1px #999;
        padding: 0 30px;
        border-radius: 56px;
        @include RCFS;
        img {
          @include wh(48px, 48px);
          object-fit: contain;
        }
        .right {
          flex: 1;
          position: relative;
          @include RCSB;
          border-bottom: solid 1px #f0f0f0;
        }
        input {
          height: 98px;
          width: 100%;
          background: none;
          color: $mainFontColor;
          font-size: 32px;
          padding-left: 20px;
        }

        /*input ~ .focus-border {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          height: 2px;
          border-radius: 2px;
          background-color: $themeColor;
          transition: 0.4s;
        }

        input:focus ~ .focus-border, input.has-content ~ .focus-border {
          width: 100%;
          transition: 0.4s;
        }*/

        .sendCode {
          position: absolute;
          right: 0;
          background: $themeColor;
          padding: 0 26px;
          border-radius: 34px;
          height: 68px;
          line-height: 68px;
          @include sc(26px, #fff);
        }
        .sendCode:active {
          background: $themeColorActive;
        }
      }
    }

    .loginBtn {
      padding-top: 15%;
      .register {
        padding-top: 8%;
        text-align: center;
        @include sc(28px, $mainFontColor);
        i {
          color: $themeColor;
        }
      }
    }

    .tipContent {
      padding: 46px;
      @include CCC;
      .title {
        @include sc(30px, #666);
        line-height: 46px;
      }
      .tel {
        padding-top: 50px;
        @include sc(30px, $mainFontColor);
        i {
          font-size: 36px;
          color: $themeColor;
          font-weight: bold;
        }
      }

      .btn {
        width: 100%;
        padding-top: 46px;
        margin-top: 66px;
        border-top: solid 1px #f2f2f2;
        span {
          display: block;
          height: 76px;
          width: 100%;
          background: $themeColor;
          line-height: 76px;
          border-radius: 38px;
          @include sc(30px, #fff);
        }
        span:active {
          background: $themeColorActive;
        }
      }
    }

    .loginBottom {
      @include RCC;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 99;
      text-align: center;
      height: 118px;
      /*line-height: 98px;*/
      .agree {
        /*padding: 46px 0;*/
        line-height: 1;
        padding-left: 15px;
        @include sc(28px, #999);
      }
      a {
        @include sc(28px, $themeColor);
      }
    }
  }
</style>
